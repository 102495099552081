import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelSelect as _vModelSelect, vModelRadio as _vModelRadio, vModelText as _vModelText, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-10" }
const _hoisted_2 = { class: "card-body d-flex align-items-center py-8" }
const _hoisted_3 = { class: "d-flex h-80px w-80px flex-shrink-0 flex-center position-relative" }
const _hoisted_4 = { class: "svg-icon svg-icon-primary position-absolute opacity-15" }
const _hoisted_5 = { class: "svg-icon svg-icon-3x svg-icon-primary position-absolute" }
const _hoisted_6 = { class: "card mb-10" }
const _hoisted_7 = { class: "card-header card-header-stretch" }
const _hoisted_8 = {
  class: "nav nav-stretch nav-line-tabs fw-bold border-0",
  role: "tablist",
  id: "kt_layout_builder_tabs",
  ref: "kt_layout_builder_tabs"
}
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = { class: "nav-item" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = {
  class: "tab-content pt-3",
  id: "kt_tabs"
}
const _hoisted_17 = { class: "row mb-10" }
const _hoisted_18 = { class: "col-lg-9 col-xl-4" }
const _hoisted_19 = { class: "form-check form-check-custom form-check-solid form-switch mb-2" }
const _hoisted_20 = { class: "row mb-10" }
const _hoisted_21 = { class: "col-lg-9 col-xl-4" }
const _hoisted_22 = { class: "row mb-10" }
const _hoisted_23 = { class: "col-lg-8 pe-10" }
const _hoisted_24 = { class: "row g-9 mb-3" }
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column text-start p-6",
  for: "sketchy-1"
}
const _hoisted_27 = { class: "d-flex mb-2" }
const _hoisted_28 = { class: "form-check form-check-custom form-check-solid form-check-sm me-5" }
const _hoisted_29 = { class: "col-6" }
const _hoisted_30 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column text-start p-6",
  for: "sigma-1"
}
const _hoisted_31 = { class: "d-flex mb-2" }
const _hoisted_32 = { class: "form-check form-check-custom form-check-solid form-check-sm me-5" }
const _hoisted_33 = { class: "col-6" }
const _hoisted_34 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column text-start p-6",
  for: "dozzy-1"
}
const _hoisted_35 = { class: "d-flex mb-2" }
const _hoisted_36 = { class: "form-check form-check-custom form-check-solid form-check-sm me-5" }
const _hoisted_37 = { class: "col-6" }
const _hoisted_38 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default d-flex flex-column text-start p-6",
  for: "unitedpalms-1"
}
const _hoisted_39 = { class: "d-flex mb-2" }
const _hoisted_40 = { class: "form-check form-check-custom form-check-solid form-check-sm me-5" }
const _hoisted_41 = { class: "row mb-10" }
const _hoisted_42 = { class: "col-lg-9 col-xl-4" }
const _hoisted_43 = { class: "form-check form-check-custom form-check-solid form-switch mb-5" }
const _hoisted_44 = { class: "form-check form-check-custom form-check-solid form-switch mb-5" }
const _hoisted_45 = { class: "row mb-10" }
const _hoisted_46 = { class: "col-lg-9 col-xl-4" }
const _hoisted_47 = { class: "row mb-10" }
const _hoisted_48 = { class: "col-lg-9 col-xl-4" }
const _hoisted_49 = { class: "row mb-10" }
const _hoisted_50 = { class: "col-lg-9 col-xl-4" }
const _hoisted_51 = { class: "form-check form-check-custom form-check-solid form-switch mb-2" }
const _hoisted_52 = { class: "row mb-10" }
const _hoisted_53 = { class: "col-lg-9 col-xl-4" }
const _hoisted_54 = { class: "row mb-10" }
const _hoisted_55 = { class: "col-lg-9 col-xl-4" }
const _hoisted_56 = { class: "row mb-10" }
const _hoisted_57 = { class: "col-lg-9 col-xl-4" }
const _hoisted_58 = { class: "row mb-10" }
const _hoisted_59 = { class: "col-lg-9 col-xl-4" }
const _hoisted_60 = { class: "switch switch-icon" }
const _hoisted_61 = { class: "form-check form-check-custom form-check-solid form-switch mb-2" }
const _hoisted_62 = { class: "row mb-10" }
const _hoisted_63 = { class: "col-lg-9 col-xl-4" }
const _hoisted_64 = { class: "switch switch-icon" }
const _hoisted_65 = { class: "form-check form-check-custom form-check-solid form-switch mb-2" }
const _hoisted_66 = { class: "row mb-10" }
const _hoisted_67 = { class: "col-lg-9 col-xl-4" }
const _hoisted_68 = { class: "row mb-10" }
const _hoisted_69 = { class: "col-lg-9 col-xl-4" }
const _hoisted_70 = { class: "card-footer py-6" }
const _hoisted_71 = { class: "row" }
const _hoisted_72 = { class: "col-lg-9" }
const _hoisted_73 = { class: "card" }
const _hoisted_74 = { class: "card-body p-10 p-lg-15" }
const _hoisted_75 = { class: "py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CodeHighlighter = _resolveComponent("CodeHighlighter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: "media/icons/duotune/abstract/abs051.svg",
              class: "h-80px w-80px"
            })
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/coding/cod009.svg" })
          ])
        ]),
        _cache[31] || (_cache[31] = _createElementVNode("div", { class: "ms-6" }, [
          _createElementVNode("p", { class: "list-unstyled text-gray-600 fw-bold fs-6 p-0 m-0" }, " The layout builder is to assist your set and configure your preferred project layout specifications and preview it in real time and export the HTML template with its includable partials of this demo. The downloaded version does not include the assets folder since the layout builder's main purpose is to help to generate the final HTML code without hassle. ")
        ], -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("ul", _hoisted_8, [
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 0 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "0",
              href: "#kt_builder_main",
              role: "tab"
            }, " Main ", 2)
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 1 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "1",
              href: "#kt_builder_header",
              role: "tab"
            }, " Header ", 2)
          ]),
          _createElementVNode("li", _hoisted_11, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 2 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "2",
              href: "#kt_builder_subheader",
              role: "tab"
            }, " Subheader ", 2)
          ]),
          _createElementVNode("li", _hoisted_12, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 3 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "3",
              href: "#kt_builder_aside",
              role: "tab"
            }, " Aside ", 2)
          ]),
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 4 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "4",
              href: "#kt_builder_content",
              role: "tab"
            }, " Content ", 2)
          ]),
          _createElementVNode("li", _hoisted_14, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.tabIndex === 5 }]),
              "data-bs-toggle": "tab",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setActiveTab($event))),
              "data-tab-index": "5",
              href: "#kt_builder_footer",
              role: "tab"
            }, " Footer ", 2)
          ])
        ], 512)
      ]),
      _createElementVNode("form", {
        class: "form",
        id: "kt_layout_builder_form",
        onSubmit: _cache[30] || (_cache[30] = ($event: any) => (_ctx.submit($event)))
      }, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 0 }]),
              id: "kt_builder_main"
            }, [
              _createElementVNode("div", _hoisted_17, [
                _cache[33] || (_cache[33] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Loader:", -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.config.loader.display) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.config.loader.display]
                    ])
                  ]),
                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "form-text text-muted" }, "Display Page Loader", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[36] || (_cache[36] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Loader Type:", -1)),
                _createElementVNode("div", _hoisted_21, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.config.loader.type) = $event))
                  }, _cache[34] || (_cache[34] = [
                    _createElementVNode("option", { value: "default" }, "Spinner", -1),
                    _createElementVNode("option", { value: "spinner-message" }, "Spinner & Message", -1),
                    _createElementVNode("option", { value: "spinner-logo" }, "Spinner & Logo", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.loader.type]
                  ]),
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "form-text text-muted" }, "Select Page Loader type", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[46] || (_cache[46] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Illustration Set:", -1)),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("label", _hoisted_26, [
                        _createElementVNode("span", _hoisted_27, [
                          _createElementVNode("span", _hoisted_28, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input",
                              type: "radio",
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.config.illustrations.set) = $event)),
                              value: "sketchy-1",
                              name: "illustrations-set",
                              id: "sketchy-1"
                            }, null, 512), [
                              [_vModelRadio, _ctx.config.illustrations.set]
                            ])
                          ]),
                          _cache[37] || (_cache[37] = _createElementVNode("span", { class: "fs-4 fw-bolder" }, "Sketchy", -1))
                        ]),
                        _cache[38] || (_cache[38] = _createElementVNode("span", {
                          style: {"background-image":"url('media/illustrations/sketchy-1/1.png')"},
                          class: "d-block h-200px w-100 bgi-no-repeat bgi-size-contain bgi-position-center"
                        }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("label", _hoisted_30, [
                        _createElementVNode("span", _hoisted_31, [
                          _createElementVNode("span", _hoisted_32, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input",
                              type: "radio",
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.config.illustrations.set) = $event)),
                              value: "sigma-1",
                              name: "illustrations-set",
                              id: "sigma-1"
                            }, null, 512), [
                              [_vModelRadio, _ctx.config.illustrations.set]
                            ])
                          ]),
                          _cache[39] || (_cache[39] = _createElementVNode("span", { class: "fs-4 fw-bolder" }, "Sigma", -1))
                        ]),
                        _cache[40] || (_cache[40] = _createElementVNode("span", {
                          style: {"background-image":"url('media/illustrations/sigma-1/1.png')"},
                          class: "d-block h-200px w-100 bgi-no-repeat bgi-size-contain bgi-position-center"
                        }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("label", _hoisted_34, [
                        _createElementVNode("span", _hoisted_35, [
                          _createElementVNode("span", _hoisted_36, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input",
                              type: "radio",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.config.illustrations.set) = $event)),
                              value: "dozzy-1",
                              name: "illustrations-set",
                              id: "dozzy-1"
                            }, null, 512), [
                              [_vModelRadio, _ctx.config.illustrations.set]
                            ])
                          ]),
                          _cache[41] || (_cache[41] = _createElementVNode("span", { class: "fs-4 fw-bolder" }, "Dozzy", -1))
                        ]),
                        _cache[42] || (_cache[42] = _createElementVNode("span", {
                          style: {"background-image":"url('media/illustrations/dozzy-1/1.png')"},
                          class: "d-block h-200px w-100 bgi-no-repeat bgi-size-contain bgi-position-center"
                        }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("label", _hoisted_38, [
                        _createElementVNode("span", _hoisted_39, [
                          _createElementVNode("span", _hoisted_40, [
                            _withDirectives(_createElementVNode("input", {
                              class: "form-check-input",
                              type: "radio",
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.config.illustrations.set) = $event)),
                              value: "unitedpalms-1",
                              name: "illustrations-set",
                              id: "unitedpalms-1"
                            }, null, 512), [
                              [_vModelRadio, _ctx.config.illustrations.set]
                            ])
                          ]),
                          _cache[43] || (_cache[43] = _createElementVNode("span", { class: "fs-4 fw-bolder" }, "United Palms", -1))
                        ]),
                        _cache[44] || (_cache[44] = _createElementVNode("span", {
                          style: {"background-image":"url('media/illustrations/unitedpalms-1/1.png')"},
                          class: "d-block h-200px w-100 bgi-no-repeat bgi-size-contain bgi-position-center"
                        }, null, -1))
                      ])
                    ])
                  ]),
                  _cache[45] || (_cache[45] = _createElementVNode("div", { class: "form-text text-muted" }, " Select one of our in-house designed illustration set and apply globally for all apps and pages ", -1))
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 1 }]),
              id: "kt_builder_header"
            }, [
              _createElementVNode("div", _hoisted_41, [
                _cache[50] || (_cache[50] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Fixed Header:", -1)),
                _createElementVNode("div", _hoisted_42, [
                  _withDirectives(_createElementVNode("input", {
                    type: "hidden",
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.config.header.fixed.desktop) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.config.header.fixed.desktop]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    type: "hidden",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.config.header.fixed.tabletAndMobile) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.config.header.fixed.tabletAndMobile]
                  ]),
                  _createElementVNode("label", _hoisted_43, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.config.header.fixed.desktop) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.config.header.fixed.desktop]
                    ]),
                    _cache[47] || (_cache[47] = _createElementVNode("span", { class: "form-check-label text-muted" }, "Desktop", -1))
                  ]),
                  _createElementVNode("label", _hoisted_44, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.config.header.fixed.tabletAndMobile) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.config.header.fixed.tabletAndMobile]
                    ]),
                    _cache[48] || (_cache[48] = _createElementVNode("span", { class: "form-check-label text-muted" }, "Tablet & Mobile", -1))
                  ]),
                  _cache[49] || (_cache[49] = _createElementVNode("div", { class: "form-text text-muted" }, "Enable fixed header", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_45, [
                _cache[53] || (_cache[53] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Menu Icon:", -1)),
                _createElementVNode("div", _hoisted_46, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.config.header.menuIcon) = $event))
                  }, _cache[51] || (_cache[51] = [
                    _createElementVNode("option", { value: "svg" }, "SVG Icon", -1),
                    _createElementVNode("option", { value: "font" }, "Font Icon", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.header.menuIcon]
                  ]),
                  _cache[52] || (_cache[52] = _createElementVNode("div", { class: "form-text text-muted" }, "Select menu icon type.", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _cache[56] || (_cache[56] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Width:", -1)),
                _createElementVNode("div", _hoisted_48, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.config.header.width) = $event))
                  }, _cache[54] || (_cache[54] = [
                    _createElementVNode("option", { value: "fluid" }, "Fluid", -1),
                    _createElementVNode("option", { value: "fixed" }, "Fixed", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.header.width]
                  ]),
                  _cache[55] || (_cache[55] = _createElementVNode("div", { class: "form-text text-muted" }, " Select header width type. ", -1))
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 2 }]),
              id: "kt_builder_subheader"
            }, [
              _createElementVNode("div", _hoisted_49, [
                _cache[58] || (_cache[58] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Display:", -1)),
                _createElementVNode("div", _hoisted_50, [
                  _withDirectives(_createElementVNode("input", {
                    type: "hidden",
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.config.toolbar.display) = $event))
                  }, null, 512), [
                    [_vModelText, _ctx.config.toolbar.display]
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.config.toolbar.display) = $event)),
                      value: "true"
                    }, null, 512), [
                      [_vModelCheckbox, _ctx.config.toolbar.display]
                    ])
                  ]),
                  _cache[57] || (_cache[57] = _createElementVNode("div", { class: "form-text text-muted" }, "Display Subheader", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_52, [
                _cache[61] || (_cache[61] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Width:", -1)),
                _createElementVNode("div", _hoisted_53, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.config.toolbar.width) = $event))
                  }, _cache[59] || (_cache[59] = [
                    _createElementVNode("option", { value: "fluid" }, "Fluid", -1),
                    _createElementVNode("option", { value: "fixed" }, "Fixed", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.toolbar.width]
                  ]),
                  _cache[60] || (_cache[60] = _createElementVNode("div", { class: "form-text text-muted" }, " Select layout width type. ", -1))
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 3 }]),
              id: "kt_builder_aside"
            }, [
              _createElementVNode("div", _hoisted_54, [
                _cache[64] || (_cache[64] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Theme:", -1)),
                _createElementVNode("div", _hoisted_55, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.config.aside.theme) = $event))
                  }, _cache[62] || (_cache[62] = [
                    _createElementVNode("option", { value: "dark" }, "Dark", -1),
                    _createElementVNode("option", { value: "light" }, "Light", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.aside.theme]
                  ]),
                  _cache[63] || (_cache[63] = _createElementVNode("div", { class: "form-text text-muted" }, "Set aside theme", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_56, [
                _cache[67] || (_cache[67] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Menu Icon:", -1)),
                _createElementVNode("div", _hoisted_57, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.config.aside.menuIcon) = $event))
                  }, _cache[65] || (_cache[65] = [
                    _createElementVNode("option", { value: "svg" }, "SVG Icon", -1),
                    _createElementVNode("option", { value: "font" }, "Font Icon", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.aside.menuIcon]
                  ]),
                  _cache[66] || (_cache[66] = _createElementVNode("div", { class: "form-text text-muted" }, "Select menu icon type.", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_58, [
                _cache[69] || (_cache[69] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Display:", -1)),
                _createElementVNode("div", _hoisted_59, [
                  _createElementVNode("span", _hoisted_60, [
                    _withDirectives(_createElementVNode("input", {
                      type: "hidden",
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.config.aside.display) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.config.aside.display]
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.config.aside.display) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.config.aside.display]
                      ])
                    ])
                  ]),
                  _cache[68] || (_cache[68] = _createElementVNode("div", { class: "form-text text-muted" }, "Display Aside", -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_62, [
                _cache[71] || (_cache[71] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Minimized:", -1)),
                _createElementVNode("div", _hoisted_63, [
                  _createElementVNode("span", _hoisted_64, [
                    _withDirectives(_createElementVNode("input", {
                      type: "hidden",
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.config.aside.minimized) = $event))
                    }, null, 512), [
                      [_vModelText, _ctx.config.aside.minimized]
                    ]),
                    _createElementVNode("div", _hoisted_65, [
                      _withDirectives(_createElementVNode("input", {
                        class: "form-check-input",
                        type: "checkbox",
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.config.aside.minimized) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _ctx.config.aside.minimized]
                      ])
                    ])
                  ]),
                  _cache[70] || (_cache[70] = _createElementVNode("div", { class: "form-text text-muted" }, " Minimize secondary panel by default ", -1))
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 4 }]),
              id: "kt_builder_content"
            }, [
              _createElementVNode("div", _hoisted_66, [
                _cache[74] || (_cache[74] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Width:", -1)),
                _createElementVNode("div", _hoisted_67, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.config.content.width) = $event))
                  }, _cache[72] || (_cache[72] = [
                    _createElementVNode("option", { value: "fluid" }, "Fluid", -1),
                    _createElementVNode("option", { value: "fixed" }, "Fixed", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.content.width]
                  ]),
                  _cache[73] || (_cache[73] = _createElementVNode("div", { class: "form-text text-muted" }, " Select layout width type. ", -1))
                ])
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane", { active: _ctx.tabIndex === 5 }]),
              id: "kt_builder_footer"
            }, [
              _createElementVNode("div", _hoisted_68, [
                _cache[77] || (_cache[77] = _createElementVNode("label", { class: "col-lg-3 col-form-label text-lg-end" }, "Width:", -1)),
                _createElementVNode("div", _hoisted_69, [
                  _withDirectives(_createElementVNode("select", {
                    class: "form-select form-select-solid",
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.config.footer.width) = $event))
                  }, _cache[75] || (_cache[75] = [
                    _createElementVNode("option", { value: "fluid" }, "Fluid", -1),
                    _createElementVNode("option", { value: "fixed" }, "Fixed", -1)
                  ]), 512), [
                    [_vModelSelect, _ctx.config.footer.width]
                  ]),
                  _cache[76] || (_cache[76] = _createElementVNode("div", { class: "form-text text-muted" }, " Select layout width type. ", -1))
                ])
              ])
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_70, [
          _createElementVNode("div", _hoisted_71, [
            _cache[79] || (_cache[79] = _createElementVNode("div", { class: "col-lg-3" }, null, -1)),
            _createElementVNode("div", _hoisted_72, [
              _cache[78] || (_cache[78] = _createStaticVNode("<input type=\"hidden\" id=\"kt_layout_builder_tab\" name=\"layout-builder[tab]\"><input type=\"hidden\" id=\"kt_layout_builder_action\" name=\"layout-builder[action]\"><button type=\"submit\" id=\"kt_layout_builder_preview\" class=\"btn btn-primary me-2\"><span class=\"indicator-label\"> Preview </span><span class=\"indicator-progress\"> Please wait... <span class=\"spinner-border spinner-border-sm align-middle ms-2\"></span></span></button>", 3)),
              _createElementVNode("button", {
                onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.reset($event))),
                type: "button",
                id: "kt_layout_builder_reset",
                class: "btn btn-active-light btn-color-muted fw-bold"
              }, " Reset ")
            ])
          ])
        ])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_73, [
      _createElementVNode("div", _hoisted_74, [
        _cache[83] || (_cache[83] = _createElementVNode("h1", {
          class: "anchor fw-bolder mb-5",
          id: "overview"
        }, "Generated config", -1)),
        _createElementVNode("div", _hoisted_75, [
          _cache[80] || (_cache[80] = _createTextVNode(" This config is used as a ")),
          _createElementVNode("b", null, _toDisplayString(_ctx.themeName), 1),
          _cache[81] || (_cache[81] = _createTextVNode(" layout config, you can update default values inside file ")),
          _cache[82] || (_cache[82] = _createElementVNode("code", null, "metronic/vue/theme/src/core/config/DefaultLayoutConfig.ts", -1))
        ]),
        _createVNode(_component_CodeHighlighter, { lang: "json" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.config), 1)
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}